import * as firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyCrtFnHINHLKXUa1ScyESXhLY0j0GTwEro",
  authDomain: "spinthewheel-setel.firebaseapp.com",
  databaseURL: "https://spinthewheel-setel.firebaseio.com",
  projectId: "spinthewheel-setel",
  storageBucket: "spinthewheel-setel.appspot.com",
  messagingSenderId: "769184332929",
  appId: "1:769184332929:web:b6c3881b148a179d"
};

firebase.initializeApp(config);

// firebase.firestore();

export default firebase;
