import React, { Component } from 'react';
import styled from 'styled-components';

const Root = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh'
});

const Container = styled.form({
  display: 'flex',
  flexDirection: 'column'
});

const MainTitle = styled.h1({
  textAlign: 'center'
});

const TextInput = styled.input({
  height: 45,
  border: '1px solid #ccc',
  padding: '0 10px',
  minWidth: 350,
  marginBottom: 10
});

const Button = styled.button({
  border: 'none',
  backgroundColor: '#07beed',
  color: '#fff',
  marginTop: 40,
  minWidth: 350,
  height: 45,
  cursor: 'pointer'
});

class LogInPage extends Component {
  state = {
    username: '',
    password: ''
  };

  componentDidMount() {
    const STORAGE_KEY = 'setel.product';
    const noVoucher = [
      { id: 'v5', qty: 0 },
      { id: 'v10', qty: 0 },
      { id: 'v20', qty: 0 },
      { id: 'v50', qty: 0 },
      { id: 'v100', qty: 0 }
    ];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(noVoucher));
  }

  handleSubmit(e) {
    e.preventDefault();

    const { username, password } = this.state;
    const { onSubmit } = this.props;
    onSubmit && onSubmit(e, { username, password });
  }

  render() {
    const { username, password } = this.state;
    return (
      <Root>
        <Container onSubmit={e => this.handleSubmit(e)}>
          <MainTitle>Log in</MainTitle>
          <TextInput
            placeholder="Username"
            type="text"
            value={username}
            onChange={({ target }) => this.setState({ username: target.value })}
          />
          <TextInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={({ target }) => this.setState({ password: target.value })}
          />
          <Button>Log In</Button>
        </Container>
      </Root>
    );
  }
}

export default LogInPage;
