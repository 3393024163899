import React, {Component, Fragment} from 'react';
import styled from 'styled-components';

import modalImage from './../assets/images/popup.png';

const Background = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0, .4)',
  zIndex: 1
});

const Content = styled.div({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  right: 0,
  bottom: 0,
  width: 1734,
  height: 1214,
  backgroundImage: `url(${modalImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  zIndex: 2,

  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const MainTitle = styled.h1({
  margin: 0,
  fontSize: '16em',
})

const SubTitle = styled.h2({
  margin: 0,
  fontSize: '6.5em',
})

class Modal extends Component {

  handleClick() {
    const {onClose} = this.props;
    onClose && onClose();
  }

  renderError() {
    return (
      <Fragment>
        <Background onClick={() => this.handleClick()} />
        <Content>
          <SubTitle>OPS! SORRY!</SubTitle>
          <SubTitle>NO MORE VOUCHERS</SubTitle>
        </Content>
      </Fragment>
    )
  }


  render() {
    const {value, error} = this.props;
    if (error) {
      return this.renderError();
    }

    return (
      <Fragment>
        <Background onClick={() => this.handleClick()} />
        <Content>
          <SubTitle>CONGRATS!</SubTitle>
          <SubTitle>YOU HAVE WON</SubTitle>
          <MainTitle>RM{value}</MainTitle>
          <SubTitle>VOUCHER</SubTitle>
        </Content>
      </Fragment>
    )
  }
}

export default Modal;