import React, { Component } from 'react';
import styled from 'styled-components';
import LogInPage from './LogIn';
import { listProducts, saveProducts } from '../helpers/product';
import firebase from './../config/firebase';

const Root = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh'
});

const Container = styled.form({
  display: 'flex',
  flexDirection: 'column'
});

const MainTitle = styled.h1({
  textAlign: 'center'
});

const NumberInput = styled.input({
  flex: 1,
  height: 45,
  border: '1px solid #ccc',
  padding: '0 10px',
  minWidth: 350,
  marginBottom: 10
});

const TextInput = styled.input({
  minHeight: 50,
  marginBottom: 10,
  padding: '0 10px'
});

const Button = styled.button({
  border: 'none',
  backgroundColor: '#07beed',
  color: '#fff',
  height: 45,
  cursor: 'pointer'
});

const FlatButton = styled.button({
  border: '1px solid #07beed',
  backgroundColor: 'transparent',
  color: '#07beed',
  height: 45,
  cursor: 'pointer',
  marginBottom: 20
});

const Content = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const ButtonContent = styled.div({
  marginTop: 20,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridColumnGap: 10
});

const Title = styled.b({
  width: 120
});

const PRODUCT_NAMES = {
  v5: 'RM 3 Voucher',
  v10: 'RM 5 Voucher',
  v20: 'RM 10 Voucher',
  v50: 'RM 20 Voucher',
  v100: 'RM 30 Voucher'
};

class AdminPage extends Component {
  state = {
    loggedIn: false,
    products: listProducts(),
    stationId: ''
  };

  handleLogIn(e, form) {
    e.preventDefault();
    const { username, password } = form;
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(user => {
        console.log('user', user);
        this.setState({ loggedIn: true });
      })
      .catch(error => {
        console.log('error', error);
        return alert(error.message);
      });
  }

  handleSaveProducts(e) {
    e.preventDefault();
    const { products } = this.state;
    const { stationId } = this.state;
    if (stationId === '' || stationId === undefined) {
      alert('invalid form');
    } else {
      saveProducts(products, stationId);
      alert('Product Updated');
    }
  }

  handleRefresh(e) {
    const products = listProducts();
    this.setState({ products });
  }

  handleProductQtyChanged(index, value) {
    const { products } = this.state;
    products[index].qty = +value;
    this.setState({ products });
  }

  renderProducts() {
    const { products } = this.state;
    return products.map((prod, index) => (
      <Content>
        <Title>{PRODUCT_NAMES[prod.id]}</Title>
        <NumberInput
          type="number"
          value={prod.qty || ''}
          onChange={({ target }) =>
            this.handleProductQtyChanged(index, target.value)
          }
        />
      </Content>
    ));
  }

  renderAdminContent() {
    const { stationId } = this.state;
    return (
      <Root>
        <Container onSubmit={e => this.handleSaveProducts(e)}>
          <MainTitle>Update Quantity</MainTitle>

          <FlatButton type="button" onClick={() => this.handleRefresh()}>
            Refresh Products
          </FlatButton>
          <TextInput
            type="text"
            placeholder="Voucher Id"
            value={stationId}
            onChange={({ target }) =>
              this.setState({ stationId: target.value })
            }
          />
          {this.renderProducts()}
          <ButtonContent>
            <Button>Update Products</Button>
          </ButtonContent>
        </Container>
      </Root>
    );
  }

  render() {
    const { loggedIn } = this.state;
    if (!loggedIn) {
      return <LogInPage onSubmit={(e, form) => this.handleLogIn(e, form)} />;
    }
    return this.renderAdminContent();
  }
}

export default AdminPage;
