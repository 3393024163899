import React, { Component } from 'react';
import styled from 'styled-components';

import rootImage from './../assets/images/background.png';
import containerImage from './../assets/images/light.gif';
import wheelImage from './../assets/images/wheel.png';
import logoImage from './../assets/images/button.png';
import arrowImage from './../assets/images/arrow.png';

import Modal from './Modal';
import { listProducts, removeProduct } from '../helpers/product';
import firebase from './../config/firebase';

const Winwheel = window.Winwheel;

const HEIGHT = 1920 * 2;
const WIDTH = 1080 * 2;

const WHEEL_SIZE = 1500;

const Root = styled.div({
  width: WIDTH,
  height: HEIGHT,
  backgroundImage: `url(${rootImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  position: 'relative',
  margin: 'auto'
});

const Arrow = styled.img({
  position: 'absolute',
  top: `${((650 / HEIGHT) * 100).toFixed(2)}%`,
  left: '50%',
  transform: 'translateX(-50%)'
});

const Container = styled.img({
  backgroundImage: `url(${containerImage})`,
  position: 'absolute',
  top: `${((825 / HEIGHT) * 100).toFixed(2)}%`,
  left: '50%',
  transform: 'translateX(-50%)'
});

const SpinWheelContainer = styled.div({
  position: 'absolute',
  top: `${((925 / HEIGHT) * 100).toFixed(2)}%`,
  left: '50%',
  transform: 'translateX(-50%)',
  width: WHEEL_SIZE,
  height: WHEEL_SIZE
});

const SpinWheel = styled.canvas({});

const Logo = styled.img({
  position: 'absolute',
  top: `${((1325 / HEIGHT) * 100).toFixed(2)}%`,
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'pointer'
});

const SEGMENTS = [
  { id: 'v100', value: 30 },
  { id: 'v5', value: 3 },
  { id: 'v10', value: 5 },
  { id: 'v5', value: 3 },
  { id: 'v20', value: 10 },
  { id: 'v5', value: 3 },
  { id: 'v50', value: 20 },
  { id: 'v5', value: 3 }
];

class WheelPage extends Component {
  state = {
    opened: false,
    value: 0,
    error: false
  };
  pathname;

  componentDidMount() {
    this.initWheel();
    this.loadVoucher();
  }

  initWheel() {
    const theWheel = new Winwheel({
      segments: SEGMENTS,
      numSegments: SEGMENTS.length,
      outerRadius: 0,
      pointerAngle: 22.5,
      drawMode: 'image',
      animation: {
        type: 'spinToStop',
        duration: 5,
        spins: 10,
        callbackFinished: () => {
          this.handleComplete();
        }
      }
    });

    let loadedImg = new Image();
    loadedImg.onload = () => {
      theWheel.wheelImage = loadedImg;
      theWheel.draw();
    };
    loadedImg.src = wheelImage;
    this.theWheel = theWheel;
  }

  start() {
    const theWheel = this.theWheel;
    const segment = this.randomPickSegment();
    if (!segment) {
      return;
    }

    const stopAt = theWheel.getRandomForSegment(segment);
    theWheel.animation.stopAngle = stopAt;
    theWheel.startAnimation();
  }

  reset() {
    const { error } = this.state;
    if (error) {
      return;
    }

    const theWheel = this.theWheel;

    theWheel.stopAnimation(false);
    theWheel.rotationAngle = 0;
  }

  loadVoucher() {
    const STORAGE_KEY = 'setel.product';
    const ref = firebase
      .firestore()
      .collection('vouchers')
      .doc(this.props.match.params.id);
    ref.get().then(doc => {
      if (doc.exists) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(doc.data().products));
      } else {
        const noVoucher = [
          { id: 'v5', qty: 0 },
          { id: 'v10', qty: 0 },
          { id: 'v20', qty: 0 },
          { id: 'v50', qty: 0 },
          { id: 'v100', qty: 0 }
        ];
        localStorage.setItem(STORAGE_KEY, JSON.stringify(noVoucher));
        alert('Your voucher code is invalid');
      }
    });
  }

  getProducts() {
    const products = listProducts();
    return products.reduceRight(
      (prev, curr) => prev.concat(Array(curr.qty).fill(curr.id)),
      []
    );
  }

  randomPickSegment() {
    const products = this.getProducts();
    if (products.length === 0) {
      return this.setState({ error: true, open: true });
    } else {
      this.setState({ error: false });
    }

    const id = products[Math.floor(Math.random() * products.length)];
    const found = this.getAllIndexes(SEGMENTS.map(s => s.id), id);
    return found[Math.floor(Math.random() * found.length)] + 1;
  }

  getAllIndexes(arr, value) {
    return arr.reduce((a, e, i) => {
      if (e === value) a.push(i);
      return a;
    }, []);
  }

  handleComplete() {
    const theWheel = this.theWheel;
    const index = theWheel.getIndicatedSegmentNumber();
    const { id, value } = theWheel.segments[index];
    removeProduct(id, this.props.match.params.id);
    this.setState({ value, open: true });
  }

  handleClose() {
    this.setState({ open: false, value: 0 });
  }

  handleClick() {
    this.reset();
    this.start();
  }

  render() {
    const { open, value, error } = this.state;
    return (
      <Root>
        <Arrow />
        <Container src={containerImage} />
        <SpinWheelContainer>
          <SpinWheel width={WHEEL_SIZE} height={WHEEL_SIZE} id="canvas">
            Canvas not supported, use another browser.
          </SpinWheel>
        </SpinWheelContainer>
        <Logo src={logoImage} onClick={() => this.handleClick()} />
        <Arrow src={arrowImage} />
        {open && (
          <Modal
            value={value}
            error={error}
            onClose={() => this.handleClose()}
          />
        )}
      </Root>
    );
  }
}

export default WheelPage;
