import firebase from './../config/firebase';

const STORAGE_KEY = 'setel.product';

export const listProducts = () => {
  let products = null;
  try {
    products = JSON.parse(localStorage.getItem(STORAGE_KEY));
  } catch (ex) {
    console.error('unable to get products');
    refreshProducts(getDefaultProducts());
  }
  products = products || getDefaultProducts();
  return products;
};

export const removeProduct = (id, pathname) => {
  const products = listProducts();
  var index = products.findIndex(prd => prd.id === id);
  products[index].qty -= 1;
  refreshProducts(products, pathname);
};

export const getDefaultProducts = () => {
  const ref = firebase
    .firestore()
    .collection('vouchers')
    .doc('default');
  return ref.get().then(doc => {
    if (doc.exists) {
      return [
        { id: 'v5', qty: doc.data().v5 },
        { id: 'v10', qty: doc.data().v10 },
        { id: 'v20', qty: doc.data().v20 },
        { id: 'v50', qty: doc.data().v50 },
        { id: 'v100', qty: doc.data().v100 }
      ];
    } else {
      throw new Error('No such document!');
    }
  });
};

export const refreshProducts = (products, pathname) => {
  products = products.map(prd => {
    prd.qty = isNaN(+prd.qty) ? 0 : prd.qty;
    return prd;
  });
  localStorage.setItem(STORAGE_KEY, JSON.stringify(products));

  firebase
    .firestore()
    .collection('vouchers')
    .doc(pathname)
    .set({ products }, { merge: true })
    .catch(error => {
      console.error('Error writing document: ', error);
    });
};

export const saveProducts = (products, stationId) => {
  firebase
    .firestore()
    .collection('vouchers')
    .doc(stationId)
    .set({
      products
    });
};

export default getDefaultProducts;
