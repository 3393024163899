import React from 'react';
import WheelPage from './home/Wheel'
import AdminPage from './admin/Admin';

import {BrowserRouter as Router, Route} from 'react-router-dom';

function App() {
  return (
    <Router>
      <Route path="/admin" exact component={AdminPage} />
      <Route path="/voucher/:id" exact component={WheelPage} />
    </Router>
  );
}

export default App;
